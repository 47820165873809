// extracted by mini-css-extract-plugin
export var column = "ResourcesAnalyticsOptions__column__dNqVi";
export var flex = "ResourcesAnalyticsOptions__flex__MR2t2";
export var flexColumn = "ResourcesAnalyticsOptions__flexColumn__bUJnx";
export var gap1 = "ResourcesAnalyticsOptions__gap1__T_uIS";
export var gap2 = "ResourcesAnalyticsOptions__gap2__vo8x6";
export var gap3 = "ResourcesAnalyticsOptions__gap3__KAqi7";
export var gap4 = "ResourcesAnalyticsOptions__gap4__s399b";
export var gap5 = "ResourcesAnalyticsOptions__gap5___GU7n";
export var popoverBody = "ResourcesAnalyticsOptions__popoverBody__Qe060";
export var popoverBodyLink = "ResourcesAnalyticsOptions__popoverBodyLink__CsYfA";
export var popoverBodyLinkIcon = "ResourcesAnalyticsOptions__popoverBodyLinkIcon__lHvWV";
export var row = "ResourcesAnalyticsOptions__row__MrMnO";